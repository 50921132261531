import './App.svelte.css';
/* src/App.svelte generated by Svelte v3.42.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Header from './Header.svelte.js';
import MainPage from './pages/MainPage.svelte.js';
import ReportListPage from './pages/ReportListPage.svelte.js';
import { onMount } from '../_snowpack/pkg/svelte.js';

function create_else_block(ctx) {
	let h1;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "404 Not Found";
		},
		m(target, anchor) {
			insert(target, h1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
		}
	};
}

// (33:41) 
function create_if_block_1(ctx) {
	let reportlistpage;
	let current;
	reportlistpage = new ReportListPage({});
	reportlistpage.$on("pageChange", /*didPageChanged*/ ctx[1]);

	return {
		c() {
			create_component(reportlistpage.$$.fragment);
		},
		m(target, anchor) {
			mount_component(reportlistpage, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(reportlistpage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(reportlistpage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(reportlistpage, detaching);
		}
	};
}

// (31:2) {#if currentPage === 'main'}
function create_if_block(ctx) {
	let mainpage;
	let current;
	mainpage = new MainPage({});
	mainpage.$on("pageChange", /*didPageChanged*/ ctx[1]);

	return {
		c() {
			create_component(mainpage.$$.fragment);
		},
		m(target, anchor) {
			mount_component(mainpage, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(mainpage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mainpage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(mainpage, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let header;
	let t0;
	let current_block_type_index;
	let if_block;
	let t1;
	let footer;
	let current;
	header = new Header({});
	const if_block_creators = [create_if_block, create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*currentPage*/ ctx[0] === 'main') return 0;
		if (/*currentPage*/ ctx[0] === 'reportList') return 1;
		return 2;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			create_component(header.$$.fragment);
			t0 = space();
			if_block.c();
			t1 = space();
			footer = element("footer");

			footer.innerHTML = `這個網站由 <a href="https://twitter.com/itszero" target="_blank" rel="noopener">傑洛</a>
    製作 | 可以在 Github 找到<a href="https://github.com/itszero/hasgooglefixedityet" target="_blank" rel="noopener">原始碼</a> | 2021-`;

			attr(footer, "class", "svelte-1cer173");
			attr(div, "class", "root svelte-1cer173");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(header, div, null);
			append(div, t0);
			if_blocks[current_block_type_index].m(div, null);
			append(div, t1);
			append(div, footer);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div, t1);
			}
		},
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(header);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let currentPage = 'main';

	function didPageChanged(e) {
		history.pushState(null, null, e.detail);
		updateCurrentPage();
	}

	function updateCurrentPage() {
		$$invalidate(0, currentPage = location.pathname.substr(1) || 'main');
	}

	function didPopState() {
		updateCurrentPage();
	}

	onMount(() => {
		updateCurrentPage();
		window.addEventListener('popstate', didPopState);
	});

	return [currentPage, didPageChanged];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;