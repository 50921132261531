import './ReportListPage.svelte.css';
/* src/pages/ReportListPage.svelte generated by Svelte v3.42.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount, createEventDispatcher } from '../../_snowpack/pkg/svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

// (104:2) {:else}
function create_else_block_1(ctx) {
	let t;

	return {
		c() {
			t = text("載入中...");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (76:2) {#if data}
function create_if_block(ctx) {
	let div0;
	let t0;
	let p;
	let t2;
	let div1;

	function select_block_type_1(ctx, dirty) {
		if (/*copied*/ ctx[1]) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block = current_block_type(ctx);
	let each_value = /*data*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div0 = element("div");
			if_block.c();
			t0 = space();
			p = element("p");
			p.textContent = "註：下表僅列出目前翻譯仍然錯誤的字詞。";
			t2 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "copy-action svelte-1odd5k9");
			attr(div1, "class", "data svelte-1odd5k9");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			if_block.m(div0, null);
			insert(target, t0, anchor);
			insert(target, p, anchor);
			insert(target, t2, anchor);
			insert(target, div1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div0, null);
				}
			}

			if (dirty & /*data, getGoogleTranslateLink, getDidClickReport*/ 1) {
				each_value = /*data*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			if_block.d();
			if (detaching) detach(t0);
			if (detaching) detach(p);
			if (detaching) detach(t2);
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (80:6) {:else}
function create_else_block(ctx) {
	let a;
	let mounted;
	let dispose;

	return {
		c() {
			a = element("a");
			a.textContent = "🔗 複製所有連結";
			attr(a, "href", "#");
		},
		m(target, anchor) {
			insert(target, a, anchor);

			if (!mounted) {
				dispose = listen(a, "click", /*didClickCopyAll*/ ctx[3]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(a);
			mounted = false;
			dispose();
		}
	};
}

// (78:6) {#if copied}
function create_if_block_2(ctx) {
	let t;

	return {
		c() {
			t = text("✅ 已經複製到剪貼簿囉！");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (88:8) {#if !word.is_fixed}
function create_if_block_1(ctx) {
	let div3;
	let div0;
	let t0_value = /*word*/ ctx[5]['english_word'] + "";
	let t0;
	let t1;
	let a;
	let t2;
	let t3_value = ' ' + "";
	let t3;
	let t4;
	let a_href_value;
	let t5;
	let div1;
	let t6;
	let t7_value = /*word*/ ctx[5]['zhtw_word'] + "";
	let t7;
	let t8;
	let div2;
	let t9;
	let t10_value = /*word*/ ctx[5]['correct_zhtw_word'] + "";
	let t10;
	let t11;
	let mounted;
	let dispose;

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			a = element("a");
			t2 = text("✏️ ");
			t3 = text(t3_value);
			t4 = text("回報錯誤");
			t5 = space();
			div1 = element("div");
			t6 = text("❌ ");
			t7 = text(t7_value);
			t8 = space();
			div2 = element("div");
			t9 = text("✅ ");
			t10 = text(t10_value);
			t11 = space();
			attr(a, "href", a_href_value = getGoogleTranslateLink(/*word*/ ctx[5]['english_word']));
			attr(a, "target", "_blank");
			attr(div1, "class", "wrong-word svelte-1odd5k9");
			attr(div2, "class", "right-word svelte-1odd5k9");
			attr(div3, "class", "word svelte-1odd5k9");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			append(div0, t0);
			append(div0, t1);
			append(div0, a);
			append(a, t2);
			append(a, t3);
			append(a, t4);
			append(div3, t5);
			append(div3, div1);
			append(div1, t6);
			append(div1, t7);
			append(div3, t8);
			append(div3, div2);
			append(div2, t9);
			append(div2, t10);
			append(div3, t11);

			if (!mounted) {
				dispose = listen(a, "click", function () {
					if (is_function(getDidClickReport(/*word*/ ctx[5]['english_word']))) getDidClickReport(/*word*/ ctx[5]['english_word']).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*data*/ 1 && t0_value !== (t0_value = /*word*/ ctx[5]['english_word'] + "")) set_data(t0, t0_value);

			if (dirty & /*data*/ 1 && a_href_value !== (a_href_value = getGoogleTranslateLink(/*word*/ ctx[5]['english_word']))) {
				attr(a, "href", a_href_value);
			}

			if (dirty & /*data*/ 1 && t7_value !== (t7_value = /*word*/ ctx[5]['zhtw_word'] + "")) set_data(t7, t7_value);
			if (dirty & /*data*/ 1 && t10_value !== (t10_value = /*word*/ ctx[5]['correct_zhtw_word'] + "")) set_data(t10, t10_value);
		},
		d(detaching) {
			if (detaching) detach(div3);
			mounted = false;
			dispose();
		}
	};
}

// (87:6) {#each data as word}
function create_each_block(ctx) {
	let if_block_anchor;
	let if_block = !/*word*/ ctx[5].is_fixed && create_if_block_1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (!/*word*/ ctx[5].is_fixed) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let a;
	let t1;
	let h20;
	let t3;
	let div0;
	let t6;
	let h21;
	let t8;
	let div1;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*data*/ ctx[0]) return create_if_block;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			a = element("a");
			a.textContent = "👈 回首頁";
			t1 = space();
			h20 = element("h2");
			h20.textContent = "我可以怎麼幫忙？";
			t3 = space();
			div0 = element("div");

			div0.innerHTML = `<p>請點開下面每個字的連結。這會打開 Google Translate
    翻譯這個詞的頁面，接著點選翻譯結果的「編輯」按鈕，送出正確的翻譯。
    <br/> 
    <img src="/assets/edit.png" alt="點選翻譯結果的「編輯」按鈕，然後送出正確的翻譯" class="svelte-1odd5k9"/></p>`;

			t6 = space();
			h21 = element("h2");
			h21.textContent = "單詞列表";
			t8 = space();
			div1 = element("div");
			if_block.c();
			attr(a, "href", "#");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			insert(target, t1, anchor);
			insert(target, h20, anchor);
			insert(target, t3, anchor);
			insert(target, div0, anchor);
			insert(target, t6, anchor);
			insert(target, h21, anchor);
			insert(target, t8, anchor);
			insert(target, div1, anchor);
			if_block.m(div1, null);

			if (!mounted) {
				dispose = listen(a, "click", /*didClickGoBack*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div1, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(a);
			if (detaching) detach(t1);
			if (detaching) detach(h20);
			if (detaching) detach(t3);
			if (detaching) detach(div0);
			if (detaching) detach(t6);
			if (detaching) detach(h21);
			if (detaching) detach(t8);
			if (detaching) detach(div1);
			if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function getGoogleTranslateLink(englishWord) {
	return `https://translate.google.com/?sl=en&tl=zh-TW&hl=zh-TW&text=${englishWord}`;
}

function getDidClickReport(word) {
	return function () {
		gtag('event', 'report', {
			event_category: 'reportList',
			event_label: word
		});
	};
}

function instance($$self, $$props, $$invalidate) {
	let data = undefined;
	let copied = false;

	onMount(async () => {
		const resp = await fetch('/i/data');
		const json = await resp.json();
		$$invalidate(0, data = json);
	});

	const dispatch = createEventDispatcher();

	function didClickGoBack() {
		dispatch('pageChange', '/');
	}

	function didClickCopyAll(e) {
		const words = data.map(word => `${word['english_word']} ${word['correct_zhtw_word']} 》 ${getGoogleTranslateLink(word['english_word'])}`);
		const toCopy = words.join('\n') + '\n\n 》最新清單可在 https://isgooglefixed.tw 找到';
		navigator.clipboard.writeText(toCopy);
		$$invalidate(1, copied = true);

		setTimeout(
			() => {
				$$invalidate(1, copied = false);
			},
			2000
		);

		gtag('event', 'copyAll', { event_category: 'reportList' });
		e.preventDefault();
		e.stopPropagation();
	}

	return [data, copied, didClickGoBack, didClickCopyAll];
}

class ReportListPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ReportListPage;