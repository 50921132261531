import './Words.svelte.css';
/* src/Words.svelte generated by Svelte v3.42.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Word from './Word.svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[1] = list[i];
	return child_ctx;
}

// (20:2) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("載入中...");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (14:2) {#if words}
function create_if_block(ctx) {
	let div;
	let current;
	let each_value = /*words*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "data svelte-1d9icje");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*words*/ 1) {
				each_value = /*words*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (16:6) {#each words as word}
function create_each_block(ctx) {
	let word;
	let current;
	word = new Word({ props: { word: /*word*/ ctx[1] } });

	return {
		c() {
			create_component(word.$$.fragment);
		},
		m(target, anchor) {
			mount_component(word, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const word_changes = {};
			if (dirty & /*words*/ 1) word_changes.word = /*word*/ ctx[1];
			word.$set(word_changes);
		},
		i(local) {
			if (current) return;
			transition_in(word.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(word.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(word, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let p0;
	let t1;
	let p1;
	let t3;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*words*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			p0 = element("p");
			p0.textContent = "本站會定期更新以下詞彙由 Google 翻譯從英文到台灣中文 (en -> zh-TW)\n    的結果。";
			t1 = space();
			p1 = element("p");
			p1.textContent = "註：詳細資訊中，第一筆資料的「第一次看到」時間通常是該字詞被加入資料庫中開始追蹤的時間，很有可能在那之前已經是這個翻譯好一陣子。";
			t3 = space();
			if_block.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p0);
			append(div, t1);
			append(div, p1);
			append(div, t3);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { words } = $$props;

	$$self.$$set = $$props => {
		if ('words' in $$props) $$invalidate(0, words = $$props.words);
	};

	return [words];
}

class Words extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { words: 0 });
	}
}

export default Words;