import './Header.svelte.css';
/* src/Header.svelte generated by Svelte v3.42.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div0;
	let t0;
	let div2;

	return {
		c() {
			div0 = element("div");
			t0 = space();
			div2 = element("div");

			div2.innerHTML = `<table class="hero svelte-p4a1is"><tr class="title_row_zhtw svelte-p4a1is"><td align="right" class="svelte-p4a1is">台灣中文<br/>zh-TW</td> 
      <td class="title_zhtw svelte-p4a1is">Google 翻譯修好了沒？</td></tr> 
    <tr><td align="right" class="svelte-p4a1is">英文<br/>en</td> 
      <td class="title_en svelte-p4a1is">Has Google Translate been fixed yet?</td></tr></table> 

  <div class="icon_to svelte-p4a1is">↓</div>`;

			attr(div0, "class", "header_bg svelte-p4a1is");
			attr(div2, "class", "header svelte-p4a1is");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			insert(target, t0, anchor);
			insert(target, div2, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t0);
			if (detaching) detach(div2);
		}
	};
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Header;