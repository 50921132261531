import './Word.svelte.css';
/* src/Word.svelte generated by Svelte v3.42.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text
} from "../_snowpack/pkg/svelte/internal.js";

import { afterUpdate } from '../_snowpack/pkg/svelte.js';
import { formatDistance, parseJSON as parseJSONDate } from '../_snowpack/pkg/date-fns.js';
import { zhTW } from '../_snowpack/pkg/date-fns/locale.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (37:6) {#if !word.is_fixed}
function create_if_block_2(ctx) {
	let t0;
	let t1_value = /*word*/ ctx[0]['correct_zhtw_word'] + "";
	let t1;
	let t2;

	return {
		c() {
			t0 = text("(");
			t1 = text(t1_value);
			t2 = text(")");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*word*/ 1 && t1_value !== (t1_value = /*word*/ ctx[0]['correct_zhtw_word'] + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (80:21) 
function create_if_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.textContent = "載入中...";
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (41:2) {#if expanded && wordHistory}
function create_if_block(ctx) {
	let div;
	let table;
	let thead;
	let t3;
	let tbody;
	let each_value = /*wordHistory*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");
			table = element("table");
			thead = element("thead");
			thead.innerHTML = `<tr><th class="svelte-1jx2txs">Google 翻譯結果</th><th class="svelte-1jx2txs">第一次看到</th><th class="svelte-1jx2txs">最後一次看到</th></tr>`;
			t3 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(table, "class", "svelte-1jx2txs");
			attr(div, "class", "history svelte-1jx2txs");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, table);
			append(table, thead);
			append(table, t3);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*formatDistance, parseJSONDate, wordHistory, Date, zhTW*/ 4) {
				each_value = /*wordHistory*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (50:10) {#each wordHistory as item}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*item*/ ctx[4]['zhtw_word'] + "";
	let t0;
	let t1;
	let td1;
	let t2_value = formatDistance(parseJSONDate(/*item*/ ctx[4]['first_seen_at']), new Date(), { addSuffix: true, locale: zhTW }) + "";
	let t2;
	let t3;
	let td2;
	let t4_value = formatDistance(parseJSONDate(/*item*/ ctx[4]['last_seen_at']), new Date(), { addSuffix: true, locale: zhTW }) + "";
	let t4;
	let t5;

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(t4_value);
			t5 = space();
			attr(td0, "class", "svelte-1jx2txs");
			attr(td1, "class", "svelte-1jx2txs");
			attr(td2, "class", "svelte-1jx2txs");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(tr, t5);
		},
		p(ctx, dirty) {
			if (dirty & /*wordHistory*/ 4 && t0_value !== (t0_value = /*item*/ ctx[4]['zhtw_word'] + "")) set_data(t0, t0_value);
			if (dirty & /*wordHistory*/ 4 && t2_value !== (t2_value = formatDistance(parseJSONDate(/*item*/ ctx[4]['first_seen_at']), new Date(), { addSuffix: true, locale: zhTW }) + "")) set_data(t2, t2_value);
			if (dirty & /*wordHistory*/ 4 && t4_value !== (t4_value = formatDistance(parseJSONDate(/*item*/ ctx[4]['last_seen_at']), new Date(), { addSuffix: true, locale: zhTW }) + "")) set_data(t4, t4_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let div2;
	let div0;
	let t0_value = (/*word*/ ctx[0].is_fixed ? '✅' : '❌') + "";
	let t0;
	let t1;
	let t2_value = /*word*/ ctx[0]['english_word'] + "";
	let t2;
	let t3;
	let t4_value = /*word*/ ctx[0]['zhtw_word'] + "";
	let t4;
	let t5;
	let t6;
	let div1;
	let t7;
	let div1_class_value;
	let t8;
	let div3_class_value;
	let mounted;
	let dispose;
	let if_block0 = !/*word*/ ctx[0].is_fixed && create_if_block_2(ctx);

	function select_block_type(ctx, dirty) {
		if (/*expanded*/ ctx[1] && /*wordHistory*/ ctx[2]) return create_if_block;
		if (/*expanded*/ ctx[1]) return create_if_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block1 = current_block_type && current_block_type(ctx);

	return {
		c() {
			div3 = element("div");
			div2 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			t2 = text(t2_value);
			t3 = text(" → ");
			t4 = text(t4_value);
			t5 = space();
			if (if_block0) if_block0.c();
			t6 = space();
			div1 = element("div");
			t7 = text("▼");
			t8 = space();
			if (if_block1) if_block1.c();
			attr(div0, "class", "main svelte-1jx2txs");
			attr(div1, "class", div1_class_value = "expander " + (/*expanded*/ ctx[1] ? 'expanded' : '') + " svelte-1jx2txs");
			attr(div2, "class", "summary svelte-1jx2txs");
			attr(div3, "class", div3_class_value = "root " + (/*expanded*/ ctx[1] ? 'expanded' : '') + " svelte-1jx2txs");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div2);
			append(div2, div0);
			append(div0, t0);
			append(div0, t1);
			append(div0, t2);
			append(div0, t3);
			append(div0, t4);
			append(div0, t5);
			if (if_block0) if_block0.m(div0, null);
			append(div2, t6);
			append(div2, div1);
			append(div1, t7);
			append(div3, t8);
			if (if_block1) if_block1.m(div3, null);

			if (!mounted) {
				dispose = [
					listen(div3, "click", /*didClick*/ ctx[3]),
					listen(div3, "mousedown", didMousedown)
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*word*/ 1 && t0_value !== (t0_value = (/*word*/ ctx[0].is_fixed ? '✅' : '❌') + "")) set_data(t0, t0_value);
			if (dirty & /*word*/ 1 && t2_value !== (t2_value = /*word*/ ctx[0]['english_word'] + "")) set_data(t2, t2_value);
			if (dirty & /*word*/ 1 && t4_value !== (t4_value = /*word*/ ctx[0]['zhtw_word'] + "")) set_data(t4, t4_value);

			if (!/*word*/ ctx[0].is_fixed) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div0, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*expanded*/ 2 && div1_class_value !== (div1_class_value = "expander " + (/*expanded*/ ctx[1] ? 'expanded' : '') + " svelte-1jx2txs")) {
				attr(div1, "class", div1_class_value);
			}

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if (if_block1) if_block1.d(1);
				if_block1 = current_block_type && current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(div3, null);
				}
			}

			if (dirty & /*expanded*/ 2 && div3_class_value !== (div3_class_value = "root " + (/*expanded*/ ctx[1] ? 'expanded' : '') + " svelte-1jx2txs")) {
				attr(div3, "class", div3_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block0) if_block0.d();

			if (if_block1) {
				if_block1.d();
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

function didMousedown(e) {
	if (e.detail > 1) {
		e.preventDefault();
	}
}

function instance($$self, $$props, $$invalidate) {
	let { word } = $$props;
	let expanded = false;
	let wordHistory;

	function didClick(e) {
		$$invalidate(1, expanded = !expanded);
	}

	afterUpdate(async () => {
		if (expanded && !wordHistory) {
			const resp = await fetch(`/i/word/${word.id}`);
			$$invalidate(2, wordHistory = await resp.json());
		}
	});

	$$self.$$set = $$props => {
		if ('word' in $$props) $$invalidate(0, word = $$props.word);
	};

	return [word, expanded, wordHistory, didClick];
}

class Word extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { word: 0 });
	}
}

export default Word;