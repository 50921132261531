import './MainPage.svelte.css';
/* src/pages/MainPage.svelte generated by Svelte v3.42.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Words from '../Words.svelte.js';
import { onMount, createEventDispatcher } from '../../_snowpack/pkg/svelte.js';

function create_if_block(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*fixedPercentage*/ ctx[3] < 0.5) return create_if_block_1;
		if (/*fixedPercentage*/ ctx[3] < 1.0) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (35:4) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("全部修好了！ 🥰");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (33:36) 
function create_if_block_2(ctx) {
	let t0;
	let span;
	let t1;
	let t2;
	let t3;
	let t4;
	let t5;

	return {
		c() {
			t0 = text("修了很多！ 😄 ");
			span = element("span");
			t1 = text("(");
			t2 = text(/*fixedCount*/ ctx[2]);
			t3 = text(" / ");
			t4 = text(/*totalCount*/ ctx[1]);
			t5 = text(")");
			attr(span, "class", "answer_note svelte-fprxlv");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, span, anchor);
			append(span, t1);
			append(span, t2);
			append(span, t3);
			append(span, t4);
			append(span, t5);
		},
		p(ctx, dirty) {
			if (dirty & /*fixedCount*/ 4) set_data(t2, /*fixedCount*/ ctx[2]);
			if (dirty & /*totalCount*/ 2) set_data(t4, /*totalCount*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(span);
		}
	};
}

// (31:4) {#if fixedPercentage < 0.5}
function create_if_block_1(ctx) {
	let t0;
	let span;
	let t1;
	let t2;
	let t3;
	let t4;
	let t5;

	return {
		c() {
			t0 = text("還沒。😥 ");
			span = element("span");
			t1 = text("(");
			t2 = text(/*fixedCount*/ ctx[2]);
			t3 = text(" / ");
			t4 = text(/*totalCount*/ ctx[1]);
			t5 = text(")");
			attr(span, "class", "answer_note svelte-fprxlv");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, span, anchor);
			append(span, t1);
			append(span, t2);
			append(span, t3);
			append(span, t4);
			append(span, t5);
		},
		p(ctx, dirty) {
			if (dirty & /*fixedCount*/ 4) set_data(t2, /*fixedCount*/ ctx[2]);
			if (dirty & /*totalCount*/ 2) set_data(t4, /*totalCount*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let div0;
	let t0;
	let h20;
	let t2;
	let div3;
	let t26;
	let h21;
	let t28;
	let div4;
	let p4;
	let t29;
	let a;
	let t31;
	let t32;
	let h22;
	let t34;
	let words;
	let current;
	let mounted;
	let dispose;
	let if_block = /*data*/ ctx[0] && create_if_block(ctx);
	words = new Words({ props: { words: /*data*/ ctx[0] } });

	return {
		c() {
			div0 = element("div");
			if (if_block) if_block.c();
			t0 = space();
			h20 = element("h2");
			h20.textContent = "為什麼要製作這個網站？";
			t2 = space();
			div3 = element("div");

			div3.innerHTML = `<div class="language-pill svelte-fprxlv">台灣中文 zh-TW</div> 
  <p>Google 翻譯已經好一陣子將台灣（語言代碼為 <code>zh-TW</code>）的翻譯改成各種中國常見的用法。Google
    甚至聲稱部分翻譯是經過「社群認證」。但是，如果你將這些詞彙丟入「Google
    趨勢」就會發現，這些詞彙並不常在台灣被使用。因此，我希望 Google
    能夠修改台灣中文的翻譯使其合乎現狀。</p> 
  <p>注：雖然選單上只有說「中文（繁體）」，如果你選擇該選項後，你會發現該選項是使用<code>zh-TW</code>的代碼，意指「台灣中文」。Google
    若不想持續支援台灣中文的翻譯也可正式宣佈不再繼續支援台灣中文並將語言代碼換成適當的選項，例如：<code>zh-Hant</code>。</p> 
  <div class="language-pill svelte-fprxlv">美式英文 en-US</div> 
  <p>Google Translate has not been accurately translating into Traditional
    Chinese (as used in Taiwan) for a while now. A lot of times the translation
    would sound like how Chinese is used in China instead. Google even claims
    some of those translations are &quot;verified by community&quot;. However, a quick
    check through Google Trends would show that these translations would indeed
    rank pretty low among Taiwanese websites. Therefore, I made this websites to
    track some examples and hopefully nudge Google into fixing the translation
    for Traditional Chinese (Taiwan).</p> 
  <p>NOTE: The Google Translate menu only says &quot;Chinese (Traditional)&quot;. However,
    if you pick the option, you will see the language code reflected in the URL
    is <code>zh-TW</code>, which means &quot;Traditional Chinese as being used in
    Taiwan&quot;. The alternative option for Google to fix this problem is to
    officially drop <code>zh-TW</code> support and switch to an appropriate
    language code instead, such as <code>zh-Hant</code>.</p>`;

			t26 = space();
			h21 = element("h2");
			h21.textContent = "我可以怎麼幫忙？";
			t28 = space();
			div4 = element("div");
			p4 = element("p");
			t29 = text("如果您有時間，請幫我們向 Google 翻譯回報翻譯問題。");
			a = element("a");
			a.textContent = "🙏 點這裡";
			t31 = text("了解詳情！");
			t32 = space();
			h22 = element("h2");
			h22.textContent = "單詞詳情";
			t34 = space();
			create_component(words.$$.fragment);
			attr(div0, "class", "answer svelte-fprxlv");
			attr(a, "href", "#");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			if (if_block) if_block.m(div0, null);
			insert(target, t0, anchor);
			insert(target, h20, anchor);
			insert(target, t2, anchor);
			insert(target, div3, anchor);
			insert(target, t26, anchor);
			insert(target, h21, anchor);
			insert(target, t28, anchor);
			insert(target, div4, anchor);
			append(div4, p4);
			append(p4, t29);
			append(p4, a);
			append(p4, t31);
			insert(target, t32, anchor);
			insert(target, h22, anchor);
			insert(target, t34, anchor);
			mount_component(words, target, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(a, "click", /*handleReportListClick*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*data*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const words_changes = {};
			if (dirty & /*data*/ 1) words_changes.words = /*data*/ ctx[0];
			words.$set(words_changes);
		},
		i(local) {
			if (current) return;
			transition_in(words.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(words.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (if_block) if_block.d();
			if (detaching) detach(t0);
			if (detaching) detach(h20);
			if (detaching) detach(t2);
			if (detaching) detach(div3);
			if (detaching) detach(t26);
			if (detaching) detach(h21);
			if (detaching) detach(t28);
			if (detaching) detach(div4);
			if (detaching) detach(t32);
			if (detaching) detach(h22);
			if (detaching) detach(t34);
			destroy_component(words, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let fixedCount;
	let totalCount;
	let data = undefined;

	onMount(async () => {
		const resp = await fetch('/i/data');
		const json = await resp.json();
		$$invalidate(0, data = json);
	});

	const dispatch = createEventDispatcher();

	function handleReportListClick(e) {
		dispatch('pageChange', 'reportList');
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	let fixedPercentage;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*data*/ 1) {
			$: $$invalidate(2, fixedCount = (data || []).filter(word => word.is_fixed).length);
		}

		if ($$self.$$.dirty & /*data*/ 1) {
			$: $$invalidate(1, totalCount = (data || []).length);
		}

		if ($$self.$$.dirty & /*fixedCount, totalCount*/ 6) {
			$: $$invalidate(3, fixedPercentage = fixedCount / totalCount);
		}
	};

	return [data, totalCount, fixedCount, fixedPercentage, handleReportListClick];
}

class MainPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default MainPage;